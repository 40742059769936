#contentHolder {
    position: absolute;
    top: 70px;
    left: calc(50% - 400px);
    width: 800px;
    height: auto;
    z-index: 8;
    margin-bottom: 100px;
}

.normalGreenButton{ background: #3b9c3f; }
.normalButton {
    font-family: open sans condensed,sans-serif;
    font-size: 1em;
    text-align: center;
    user-select: none;
    transition: background .5s;
    cursor: pointer;
    border-radius: 4px;
    padding: 2px;
    line-height: 30px;
    width: 150px;
    color: #fff;
    margin: 40px 320px;
}