#testContainerInner {
    width: 52%;
    float: left;
    border: 0 solid blue;
    margin: 0 0 50px 0;
}

.modul-title {
    padding-top: 30px;
}
  
#testContainerRight {
    float: right;
    width: 10.5%;
    border: 0 solid #000;
    margin: 20px 20px;
    text-align: center;
}

#testContainerLeft {
    float: left;
    width: 22.5%;
    display: table;
    border: 0 solid #000;
}

#logo-soechi {
    padding: 15px;
}

#testTimer {
    font-family: "Chivo-MediumItalic";
    border: none;
    background: #dfe3ef;
    color: #908c8c;
    text-align: center;
    border-radius: 50%;
    display: inline-block;
    padding: 30px 8px 30px 12px;
    letter-spacing: 3px;
    width: 85px;
    margin: 0 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#testQuestionNum {
    margin-top: 10%;
    border: 1px solid #464646;
    border-width: 1px 0;
    color: #000;
    text-align: center;
}

#testQuestionNumSelector {
    width: 100%;
    float: right;
    margin-top: 10px;
}

#testQuestionNumSelectorCol1 {
    font-family: "Chivo-MediumItalic";
    margin-left: 10%;
    height: 100%;
    float: left;
    margin-top: 10px;
}

#testQuestionNumSelectorCol2 {
    font-family: "Chivo-MediumItalic";
    margin-top: 10px;
    margin-left: 10%;
    width: 27%;
    height: 100%;
    float: left;
}

.questionNumSelect {
    font-size:0.8em;
    height:25px;
    font-size: 12px;
    border-radius:8px;
    margin-bottom:5px;
    padding-top:3px;
    opacity:1;
    pointer-events:auto;
    float: left;
    color: grey;
    background: #d4d4d4;
    text-align: center;
    cursor: pointer;
}

.questionNumSelect:hover {
    background: #b0b0b0;
    color: #fff;
    transition: background .5s,color .5s;
}

.questionNumAnswered {
    font-size:0.8em;
    height:25px;
    font-size: 12px;
    border-radius:8px;
    margin-bottom:5px;
    padding-top:3px;
    opacity:1;
    pointer-events:auto;
    float: left;
    color: grey;
    background: #8cda99;
    text-align: center;
    cursor: pointer;
}

.questionNumPresent {
    font-size:0.8em;
    height:25px;
    font-size: 12px;
    border-radius:8px;
    margin-bottom:5px;
    padding-top:3px;
    opacity:1;
    pointer-events:auto;
    float: left;
    color: #fff;
    background: #6683af;
    text-align: center;
}

.questionBlock {
    font-family: "Chivo-Italic";
    color: #000;
    text-align: center;
    border: none;
    border-radius: 50px;
    padding: 40px 15px;
    background: #f2f5fd;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-size: 25px;
    margin: 50px 0 30px 0;
}

.answerBlock, .answerLastBlock {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border: none;
    border-radius: 20px;
    padding: 5px 15px;
}

.answerLastBlock {
    margin: 0px 40px 20px 185px;
}

.answerBlock:hover, .answerLastBlock:hover {
    cursor: pointer;
    background: #fff;
    color: #000;
    transition: background .5s,color .5s;
}

.answerBlock.visited, .answerLastBlock.visited {
    background: #fff;
    color: #000;
    transition: background .5s,color .5s;
}

.answerBlock.disabled, button:disabled, button[disabled] {
    background: #b0b0b0;
    color: #9f9595;
    transition: background .5s,color .5s;
    pointer-events:none;
}

.answerAlphabet {
    padding: 0;
    border: none;
    border-radius: 20px;
    font-family: "Chivo-MediumItalic";
}

.answerText {
    text-align: center;
    font-family: "Chivo-MediumItalic";
}

.answerbox {
    display: none;
}

.nextBtn {
    float: right;
    margin-bottom: 20px;
}

.prevBtn {
    float: left;
    margin-bottom: 20px;
}
  